import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import topNav from '@model/topNav';
import './NavTop.css';


const NavTop: FC = (): JSX.Element => {
  const navLinkClass: string = 'nav-top__item';

  return (
    <nav className="nav-top">
      <input type="checkbox" className="nav-top__toggle" name="nav-top__toggle" id="nav-top__toggle" />
      <label className="nav-top__hamburger" htmlFor="nav-top__toggle">
        <span className="hamburger__line"></span>
        <span className="hamburger__line"></span>
        <span className="hamburger__line"></span>
      </label>
      <div className="nav-top__links">
        {topNav.map(navData => <NavLink to={navData.to} className={navLinkClass}>{navData.text}</NavLink>)}
      </div>
    </nav>
  );
}

export default NavTop;
