import { FC, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { RouteInterface } from '@interface';
import routes from '@config/routes';
import DefaultLayout from '@layout/DefaultLayout/DefaultLayout';

interface RouteSwitchProps {};

const RouteSwitch: FC<RouteSwitchProps> = (): JSX.Element => {
  return (
    <Routes>
      {routes.map((route: RouteInterface, index: number) => {
        const RenderComponent: any = route.component;
        const RenderElement = (props: any): JSX.Element => (
              <DefaultLayout key={index} pageHeading={{headingText: route.componentProps.title}}>
                <Suspense fallback={<div>Loading...</div>}>
                  <RenderComponent
                    {...props}
                    {...route.componentProps}
                  />
                </Suspense>
              </DefaultLayout>
            )
        return (
          <Route 
            key={index}
            path={route.path}
            element={<RenderElement/>}
          />
        )
      })}
    </Routes>
  );
}

export default RouteSwitch;
