import { FC } from 'react';
import { Heading } from '@block';
import './Hero.css';

interface HeroProps {
  mainHeadingText: string;
};

const Hero: FC<HeroProps> = ({ mainHeadingText }): JSX.Element => {
  return (
    <div className="hero">
      <Heading level={1}>{mainHeadingText}</Heading>
    </div>
  );
}

export default Hero;
