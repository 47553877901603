import { FC } from 'react';
import { Footer, Header, ViewHead } from '@block';
import './DefaultLayout.css';

interface DefaultLayoutProps {
  pageHeading: any;
  children: JSX.Element;
};

const DefaultLayout: FC<DefaultLayoutProps> = ({pageHeading, children }): JSX.Element => {
  return (
    <>
      <Header text={'Larzilla'.toUpperCase()} />
        <div id="layout-container">
          <div className="layout-content">
            <ViewHead headingText={pageHeading.headingText}/>
            {children}
          </div>
        </div>
      <Footer text="Larzilla LLC" />
    </>
  );
}

export default DefaultLayout;
