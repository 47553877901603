import NavItemInterface from "@interface/NavItemInterface";
const topNav: NavItemInterface[] = [
  {
    to: '/',
    text: 'Home',
    sort: 1
  },
  {
    to: '/showcase',
    text: 'Showcase',
    sort: 2
  },
  {
    to: '/services',
    text: 'Services',
    sort: 3
  },
  {
    to: '/about',
    text: 'About',
    sort: 4
  },
  {
    to: '/contact',
    text: 'Contact',
    sort: 1
  }
]

export default topNav;
