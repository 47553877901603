import { RouteInterface } from '@interface';
import { lazyLoad } from '@utility/lazy/lazyLoad';

const routes: RouteInterface[] = [
  {
    path: '/',
    name: "Home",
    component: lazyLoad(import('@view/Home/HomeView')),
    private: false,
    exact: true,
    componentProps: {}
  },
  {
    path: '/about',
    name: "About",
    component: lazyLoad(import('@view/About/AboutView')),
    private: false,
    exact: true,
    componentProps: {
      title: "About"
    }
  },
  {
    path: '/services',
    name: "Services",
    component: lazyLoad(import('@view/Services/ServicesView')),
    private: false,
    exact: true,
    componentProps: {
      title: "Services"
    }
  },
  {
    path: '/showcase',
    name: "Showcase",
    component: lazyLoad(import('@view/Showcase/ShowcaseView')),
    private: false,
    exact: true,
    componentProps: {
      title: "Showcase"
    }
  },
  {
    path: '/contact',
    name: "Contact",
    component: lazyLoad(import('@view/Contact/ContactView')),
    private: false,
    exact: true,
    componentProps: {
      title: "Contact"
    }
  }
];

export default routes;
